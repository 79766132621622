body {
  margin: 0;
  font-family:
    "Inter Regular",
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: button;
  cursor: pointer;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  background-color: #f9fafb;
  color: #25233f;
}

a {
  color: blue;
  text-decoration: underline;
}

@font-face {
  font-family: "BN Bergen St Medium";
  src: url("/public/static/fonts/64079bffb731004e6c0d9c1e_BNBergenSt-Medium.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BN Bergen St";
  src: url("/public/static/fonts/64079bffca237b2cbe896ae7_BNBergenSt.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BN Bergen St Light";
  src: url("/public/static/fonts/64079bffe42fe84e16ce1abd_BNBergenSt-Light.otf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Regular";
  src: url("/public/static/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
