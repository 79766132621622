.leftPaneSearch {
  align-self: center;
  width: 100%;
  margin-top: 0.938rem;
}

.leftPaneSearchBar {
  align-self: center;
  background: #eeeeef;
  border-radius: 10px;
  width: 100%;
}

.view {
  font-family: "BN Bergen St";
  font-style: normal;
}

.view h1 {
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 150%;
}

.paperTableButton {
  text-transform: none;
  font-size: 0.875rem;
}

.paperTableRow {
  margin-bottom: 0.938rem;
  height: 6.875rem;
  background-color: #fff;
  border-radius: 20px;
  padding: 29px 20px 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "PT Sans";
}

.paperTable {
  background-color: #f4f4f4;
  border: 1px solid #e2e2ea;
  border-radius: 23px;
}

.paperTable h3 {
  padding: 15px 20px 30px 20px;
  margin-right: 2.5rem;
}

.paperTableTwoValueCell {
  border-left: 1px solid black;
  padding-left: 0.438rem;
  font-size: 1rem;
  color: #171725;
}
