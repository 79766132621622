.leftPaneOpen {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
  width: 18rem;
  border-right: 1px solid rgb(var(--callout-border-rgb));
  background-color: #25233f;
  color: #ffffff;
  min-height: 100vh;
  border-radius: 0px 20px 20px 0px;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  transition: width 1s;
}

.leftPaneClosed {
  width: 0px;
  min-height: 100vh;
  background-color: #25233f;
  border-radius: 0px 20px 20px 0px;
  transition: width 1s;
}

.leftPaneLogo {
  align-self: center;
  margin-bottom: 1.875rem;
}

.navigation {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.MuiInputBase-root.globalFilterSelect {
  height: 2.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%;
  background-color: #1e1d31;
  border-radius: 8px;
}

.globalFilter {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.globalFilterLabel {
  display: flex;
  gap: 12px;
  align-items: center;
  color: #ffffff;
}

.globalFilterLabel h3 {
  font-family: "Inter", "Inter Regular";
  font-size: 1rem;
  line-height: 1.5px;
  font-weight: 400;
}
