.paymentHeaderCell {
  border-left: 1px solid #d0d5dd;
  padding: 0 16px;
}

.paymentHeaderTypography {
  font-size: 1rem;
  weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
}

.paymentHeaderKey {
  color: #475467;
}
